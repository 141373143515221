body {
  font-size: 14px;
  color: var(--text);
  overflow-y: scroll;
  font-family: Inter, -apple-system, BlinkMacSystemFont, PingFang SC, Hiragino Sans GB, noto sans, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  background-color: #F8FAFB;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  -webkit-font-smoothing: unset !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main-content {
  padding: 4px;
}

.small-icon .icon {
  font-size: 1em !important;
}

.custom-footer {
  font-size: 1.1em;
}

@media only screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* v3 */

:root {
  --border: #e4e7ed;
  --border-light: #F3F3F4;
  --primary: #05D1B2;
  --primary-dark: #04ac93;
  --primary-light: #ebfffc;
  --danger: #F14667;
  --danger-dark: #d52b4d;
  --danger-light: #feecf0;
  --warning: #ffe08a;
  --warning-dark: #946c00;
  --warning-light: #fffaeb;
  --success: #4AC68E;
  --success-dark: #2aaf74;
  --success-light: #EFFAF5;
  --info: #656CFD;
  --info-dark: #434fcf;
  --info-light: #f4f4ff;
  /* f4f4ff */
  --gray: #eceef1;
  --gray-dark: #e6e6eb;
  --muted: #8492A6;
  --text: #3C4858;
  --text-2: #5E5F60;
  --text-3: #A8AEB9;
  --fill: #F0F2F6;
}

.visible.transition {
  display: block !important;
  visibility: visible !important;
}

.ui.vertical.segment {
  border-radius: 0 !important;
  padding: 1rem !important;
  text-align: center;
}

.custom-footer {
  padding: 6px;
  font-size: 14px !important
}
.bo{
  border:1px solid var(--border);
}
.grid {
  display: grid
}

.flex {
  display: flex
}

.flex-wrap {
  flex-wrap: wrap;
}

.w-full {
  width: 100%
}

.flex-y-center {
  display: flex;
  align-items: center;
}

.flex-col {
  flex-direction: column
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.order-2 {
  order: 2;
}

.flex-end {
  justify-content: flex-end;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}
.ml-auto {
  margin-left: auto;
}
.fw-400 {
  font-weight: 400;
}
.text-center {
  text-align: center;
}

.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.cursor-pointer{
  cursor: pointer
}
.gap-5 {
  gap: 1.25rem
}

.text-small {
  font-size: 14px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.abs-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.abs-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.rotate-180 {
  transform: rotate(180deg);
}

.z-1 {
  z-index: 1;
}

.z-10 {
  z-index: 10;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-12 {
  padding-top: 3rem;
}

.-mt-2{
  margin-top: -0.5rem;
}
.m-0 {
  margin: 0 !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem
}
.mb-6 {
  margin-bottom: 1.5rem
}
.mb-8 {
  margin-bottom: 2rem;
}

.mb-12 {
  margin-bottom: 3rem
}

.text-left {
  text-align: left;
}

.text-muted {
  color: var(--muted);
  font-size: 15px;
}
.text-sm{
  font-size: 13px;
}
.text-base {
  font-size: 16px;
}

.text-lg {
  font-size: 18px;
}

.text-white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}
.p-1{
  padding: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3{
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}
.mt-5 {
  margin-top: 1.25rem;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.rd-full {
  border-radius: 9999px !important
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}
@media (min-width:768px) {
  .ui.segment {
    padding: 2rem !important
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:flex-col {
    flex-direction: column
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-3\/5 {
    width: 60%;
  }
}

@media (min-width:1024px) {
  .ui.segment {
    padding: 3rem !important
  }

  .ui.container.main-content {
    padding-top: 3rem !important;
  }

  .lg\:p-8 {
    padding: 2rem !important
  }
}

.page-home {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.page-home .container {
  padding-left: 1rem;
  padding-right: 1rem;
}

.page-home .section-title {
  padding-top: 5rem;
}

.page-home .section-title h2 {
  font-size: 2rem;
}

.page-home .logo {
  margin-bottom: -4rem;
}

.page-home .ui.table th {
  font-size: 16px;
}

.page-home .ui.table th,
.page-home .ui.table td {
  text-align: left;
}

.test-gpt-4 .label {
  min-width: 70px;
  text-align: center;
}
.help-block{
  display: none;
}
.page-home iframe {
  width: 100%;
  height: 520px;
  border: none;
  background-color: #fff;
  /* box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0px 1px, rgba(150, 158, 166, 0.2) 0px 8px 24px; */
}
.page-chat iframe{
  width: 100%;
  height: 85vh;
  border: none;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0px 1px, rgba(150, 158, 166, 0.2) 0px 8px 24px;
}
.overlay {
  background: linear-gradient(90deg, #FF5EEF, #456EFF);
  -webkit-clip-path: ellipse(148% 52% at 50% -18%);
  clip-path: ellipse(148% 52% at 50% -18%);
  /* -webkit-clip-path: ellipse(148% 45% at 50% -18%); */
  /* clip-path: ellipse(148% 45% at 50% -18%); */
  /* min-height: 1300px; */
  height: 100dvh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;
}

.shadow-lg {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, .03);
}
.home-notice table{
  font-size: 14px;
  max-width: 800px;
  margin: 1rem auto 1rem auto!important;
  border-top: 1px solid var(--border-light);
}
.home-notice table th {
  font-weight: 700;
  white-space: nowrap;
}
.text-info{
  color: #6366f1;
}
.a-info{
  color: var(--info);
}
.a-info:hover{
  color: var(--info-dark);
}
.status-images{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.status-images img{
  border-radius: 4px;
  margin: 5px;
}